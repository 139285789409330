import axios from 'axios';

const state = {

};

const getters = {
};

const actions = {
    async getPendingApplication(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getPendingApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        data.id = state.rootState.user.schoolId;
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async updateSchoolData(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateSchoolDataUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async deleteSchoolData(state, data) {//
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteSchoolDataUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async submitAccreditationApplication(state, id) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.submitAccreditationApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id : id }, { headers: header});// make api call
        return response.data;
    },
    async getPaginatedApplicationItems(state, url) {// get paginated applications
        const authorizationToken = state.rootState.url.authorizationToken;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {}, { headers: header});// make api call
        return response.data;
    },
    async getSchoolApplications(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSchoolApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async getApplication(state, id) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id: id }, { headers: header});// make api call
        return response.data;
    },
    async requestVerifyVisit(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.requestVerifyVisitUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async uploadBulkSchoolStaff(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadBulkSchoolStaffUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}