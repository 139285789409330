import axios from 'axios';

const perPage = 20;

const state = {
    accessToken: localStorage.getItem('access_token') || null,
    schoolId: localStorage.getItem('school_id') || null,
    school: {},
    lock: false,
    uploadProgress: null,
    perPage: perPage,
    notifications: {}
};

const getters = {
    isLoggedIn: state => state.accessToken !== null && state.schoolId !== null ? true : false,
    school: state => state.school,
    lock: state => state.school && (state.school.profile_lock == 'yes') ? true : false,
    locations: state => state.locations,
    notifications: state => state.notifications
};

const actions = {
    async getSchools(state) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSchoolsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url,{}, { headers: header });// make api call
        return response.data;
    },
    async createSignup(state, data) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.createSignupUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data, { headers: header });// make api call
        return response.data;
    },
    async schoolLogin(state, data) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.schoolLoginUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data, { headers: header });// make api call
        return response.data;
    },
    storeAndLoginUser(state, data) {//set token and login user
        let hours = 2;
        localStorage.setItem('access_token', data.access_token);// set access token
        localStorage.setItem('school_id', data.school_id);// set hospital id
        localStorage.setItem('time_out', new Date().getTime() + (hours * 60 * 60 * 1000));// set timeout
    },
    destroyAccessToken(state) {// destroy local storage
        localStorage.removeItem('access_token');// remove token
        localStorage.removeItem('school_id');// remove school id
        localStorage.removeItem('time_out');// remove time out
        return state.accessToken = null;
    },
    async fetchSchoolProfile(state) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.schoolProfileUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { id : state.state.schoolId}, { headers: header });// make api call
        state.commit('setSchool', response.data.school);
        return response.data.school;
    },
    async changePassword(state, data) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.changePasswordUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data, { headers: header });// make api call
        return response.data;
    },
    async uploadUserPhoto(state, data) {// upload user documents
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadUserPhotoUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, {
            headers: header,
            onUploadProgress: uploadEvent => {
                state.state.uploadProgress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
            }
        });// make api call
        return response.data;
    },
    updatePhotoName({ commit }, fileName) {// update photo name
        commit('setUpdatePhotoName', fileName);// set update photo function
    },
    async getSchoolNotifications(state, limit) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSchoolNotificationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { id : state.state.schoolId, limit : limit }, { headers: header });// make api call
        state.commit('setSchoolNotifications', response.data);
        return response.data;
    },
    async getSchoolNotification(state, slug) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSchoolNotificationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { slug : slug }, { headers: header });// make api call
        return response.data;
    },
    async deleteSchoolNotification(state, id) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteSchoolNotificationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { id : id }, { headers: header });// make api call
        return response.data;
    },
    async getSchoolLetters(state) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSchoolLettersUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { id : state.state.schoolId}, { headers: header });// make api call
        return response.data;
    },
};

const mutations = {
    setSchool: (state, school) => (state.school = school),// set school profile
    setUpdatePhotoName: (state, fileName) => (state.school.photo = fileName),// set user basic profile only
    setSchoolNotifications(state, data) {// set user notifications
        state.notifications.records = data.notifications.data;
        state.notifications.total = data.total;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}