import axios from 'axios';

const state = {

};

const getters = {
};

const actions = {
    async getAccreditationPayment(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAccreditationPaymentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        data.id = state.rootState.user.schoolId;
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async saveSchoolTransaction(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveSchoolTransactionUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        data.id = state.rootState.user.schoolId;
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async accreditationRrrStatus(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.accreditationRrrStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        data.id = state.rootState.user.schoolId;
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async generateRrr(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.generateRrrUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async getSchoolPayments(state, limit) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSchoolPaymentsUrl;
        const schoolId = state.rootState.user.schoolId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id: schoolId, limit : limit }, { headers: header});// make api call
        return response.data;
    }
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}