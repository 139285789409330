var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (! _vm.isLoadingBlade)?_c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"MDCN","title":"MDCN"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-tv-2 text-primary',
          path: '/dashboard'
        }}}),_c('sidebar-item',{attrs:{"link":{name: 'My Account', icon: 'ni ni-single-02 text-yellow', path: '/profile'}}}),_c('sidebar-item',{attrs:{"link":{name: 'Change Password', icon: 'ni ni-settings-gear-65 text-red', path: '/change-password'}}}),_c('sidebar-item',{attrs:{"link":{name: 'My Applications', icon: 'ni ni-planet text-blue', path: '/applications'}}}),_c('sidebar-item',{attrs:{"link":{name: 'Verification Visit', icon: 'ni ni-bell-55 text-dark', path: '/verification-visit'}}}),(_vm.school && (_vm.school.approved_status == 'yes') && ((_vm.school.request_type == 'advisory-visit') || 
              ((_vm.school.request_type == 'accreditation') 
                && (_vm.school.accredit_status != 'final-clinical-accreditation')
                && (_vm.school.accredit_status != 're-accreditation'))))?_c('sidebar-item',{attrs:{"link":{
          name: 'Accreditation', 
          icon: 'ni ni-folder-17 text-red', 
          path: '/new-application/accreditation'
        }}}):_vm._e(),(_vm.school && (_vm.school.approved_status == 'yes') && (((_vm.school.request_type == 'accreditation') 
              && ((_vm.school.accredit_status == 'final-clinical-accreditation') 
              || ((_vm.school.accredit_status == 're-accreditation')))) 
              || (_vm.school.request_type == 're-accreditation')))?_c('sidebar-item',{attrs:{"link":{
          name: 'Re-Accreditation', 
          icon: 'ni ni-folder-17 text-red', 
          path: '/new-application/re-accreditation'
          }}}):_vm._e(),(_vm.school && (_vm.school.request_type == 'advisory-visit'))?_c('sidebar-item',{attrs:{"link":{
          name: 'New Advisory Visit', 
          icon: 'ni ni-bullet-list-67 text-orange', 
          path: '/new-application/advisory'
        }}}):_vm._e(),(_vm.school && _vm.school.index_status)?_c('sidebar-item',{attrs:{"link":{name: 'Indexing',
        icon: 'ni ni-folder-17 text-green',
        path: '/indexing'}}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{name: 'My Notifications', icon: 'ni ni-bell-55 text-yellow', path: '/notifications'}}}),_c('sidebar-item',{attrs:{"link":{name: 'My Payments', icon: 'ni ni-credit-card text-red', path: '/payments'}}}),_c('sidebar-item',{attrs:{"link":{name: 'My Letters', icon: 'ni ni-bullet-list-67 text-green', path: '/letters'}}}),_c('sidebar-item',{attrs:{"link":{name: 'Requirements', icon: 'ni ni-folder-17 text-info', path: '/requirements-policy'}}}),_c('sidebar-item',{attrs:{"link":{name: 'Logout', icon: 'ni ni-user-run text-pink', path: '/logout'}}})],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view',{key:_vm.$route.fullPath})],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }