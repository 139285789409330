<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }" v-if="! isLoadingBlade">
    <side-bar
      :background-color="sidebarBackground"
      short-title="MDCN"
      title="MDCN"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard'
          }"
        />

        <sidebar-item :link="{name: 'My Account', icon: 'ni ni-single-02 text-yellow', path: '/profile'}"/>
        <sidebar-item :link="{name: 'Change Password', icon: 'ni ni-settings-gear-65 text-red', path: '/change-password'}"/>
        <sidebar-item :link="{name: 'My Applications', icon: 'ni ni-planet text-blue', path: '/applications'}"/>
        <sidebar-item :link="{name: 'Verification Visit', icon: 'ni ni-bell-55 text-dark', path: '/verification-visit'}"/>
        <sidebar-item 
          :link="{
            name: 'Accreditation', 
            icon: 'ni ni-folder-17 text-red', 
            path: '/new-application/accreditation'
          }"
          v-if="school && (school.approved_status == 'yes') && ((school.request_type == 'advisory-visit') || 
                ((school.request_type == 'accreditation') 
                  && (school.accredit_status != 'final-clinical-accreditation')
                  && (school.accredit_status != 're-accreditation')))"
        />
        <sidebar-item 
          :link="{
            name: 'Re-Accreditation', 
            icon: 'ni ni-folder-17 text-red', 
            path: '/new-application/re-accreditation'
            }"
          v-if="school && (school.approved_status == 'yes') && (((school.request_type == 'accreditation') 
                && ((school.accredit_status == 'final-clinical-accreditation') 
                || ((school.accredit_status == 're-accreditation')))) 
                || (school.request_type == 're-accreditation'))"
        />
        <sidebar-item 
          :link="{
            name: 'New Advisory Visit', 
            icon: 'ni ni-bullet-list-67 text-orange', 
            path: '/new-application/advisory'
          }"
          v-if="school && (school.request_type == 'advisory-visit')"
        />
        <sidebar-item
          :link="{name: 'Indexing',
          icon: 'ni ni-folder-17 text-green',
          path: '/indexing'}"
          v-if="school && school.index_status"
        />
        <sidebar-item :link="{name: 'My Notifications', icon: 'ni ni-bell-55 text-yellow', path: '/notifications'}"/>
        <sidebar-item :link="{name: 'My Payments', icon: 'ni ni-credit-card text-red', path: '/payments'}"/>
        <sidebar-item :link="{name: 'My Letters', icon: 'ni ni-bullet-list-67 text-green', path: '/letters'}"/>
        <sidebar-item :link="{name: 'Requirements', icon: 'ni ni-folder-17 text-info', path: '/requirements-policy'}"/>
        <sidebar-item :link="{name: 'Logout', icon: 'ni ni-user-run text-pink', path: '/logout'}"/>

      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view :key="$route.fullPath"></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';
  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        isLoadingBlade: true,
        sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
      };
    },
    computed: {
      ...mapGetters(['school'])
    },
    methods: {
      ...mapActions(['fetchSchoolProfile']),
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      checkStatus() {// check for user account status
        this.fetchSchoolProfile().then((res) => {
            this.stopLoading();
        });
      },
      stopLoading() {
        var self = this;
        setTimeout(function() { self.isLoadingBlade = false; }, 2000);
      }
    },
    created() {
      this.stopLoading();
    }
  };
</script>
<style lang="scss">
</style>
