import axios from 'axios';

const state = {

};

const getters = {

};

const actions = {
    async getIndexingYear(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getIndexingYearUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        data.school_id = state.rootState.user.schoolId;
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async saveIndexing(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveIndexingUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async deleteIndexStudent(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteIndexStudentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async submitIndexStudentsList(state, id) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.submitIndexStudentsListUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id: id }, { headers: header});// make api call
        return response.data;
    },
    async getIndexingApplications(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getIndexingApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async searchIndexingStudent(state, matric_no) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.searchIndexingStudentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { matric_no : matric_no }, { headers: header});// make api call
        return response.data;
    },
    async updateIndexingStudent(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateIndexingStudentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async uploadBatchIndexing(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadBatchIndexingUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}