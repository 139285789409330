import Vuex from 'vuex';
import Vue from 'vue';
import user from './modules/user';
import application from './modules/application/application';
import parameter from './modules/parameter/parameter';
import payment from './modules/payment/payment';
import url from './modules/url/url';
import indexing from './modules/indexing/indexing';

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
    modules: {
        user,
        application,
        parameter,
        payment,
        url,
        indexing
    }
});

