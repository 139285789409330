<template>
  <footer class="footer px-4 bg-white">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{year}} Medical and Dental Council of Nigeria
        </div>
      </div>
      <div class="col-lg-6">
        <ul class="nav nav-footer justify-content-center justify-content-lg-end">
          <div class="copyright text-center text-lg-left text-muted">
            Designed By
            <a href="javascript:void(0)" target="_blank">
              BCF
            </a>
          </div>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
  import { mapActions } from 'vuex';

  export default {
    data() {
      return {
        year: new Date().getFullYear() 
      };
    },
    methods: {
      ...mapActions(['fetchSchoolProfile']),
      checkStatus() {// check for user account status
        this.fetchSchoolProfile().then((res) => {
            if(res && res.status == 'new') {// check if it new account
              if((this.$route.name != 'Verification Page')) {
                this.$router.push({ name: 'Verification Page' });
              }
            }
        });
      },
    },
    mounted() {
      this.checkStatus();// get profile
    }
  };
</script>
<style scoped>
  .bg-white {
      background-color: #e63c4705 !important;
  }
</style>
